import ReactMarkdown from "react-markdown";
import Button from "@/components/Forms/Button";
import Footer from "@/components/Sections/Footer";
import Navigation from "@/components/Sections/Navigation";

import Router from "next/router";

import { useState, useEffect, useCallback } from "react";
import { useAutoAnimate } from "@formkit/auto-animate/react";

const FAQ = ({ question, answer }: { question: string; answer: string }) => {
    const [open, setOpen] = useState(false);
    const [parent] = useAutoAnimate<HTMLDivElement>();

    return (
        <div ref={parent} className="flex flex-col gap-5 bg-purple-200 py-8 px-11 rounded-lg w-full cursor-pointer">
            <div className="flex flex-row justify-between items-center gap-4 duration-200 hover:opacity-75" onClick={() => setOpen(!open)}>
                <h3 className="font-semibold text-xl leading-normal">{question}</h3>
                <span className={`material-icons-outlined text-4xl duration-500 transform ${open && "rotate-180"}`}>expand_more</span>
            </div>

            {open && <ReactMarkdown className="leading-relaxed prose prose-invert">{answer}</ReactMarkdown>}
        </div>
    );
};

const Slider = () => {
    const [isShown, setIsShown] = useState(false);
    const [currentSlide, setSlide] = useState(0);
    const [parent] = useAutoAnimate<HTMLDivElement>();

    const slides = [
        {
            button: {
                icon: "handshake",
                iconSrc: "/images/twitter/emojis/wave.svg",
                text: "Greetings",
            },
            text: {
                title: "Warm greetings for your new member!",
                description: "Send a warm welcome to your new member with a personalized invite.",
            },
            imageUri: "/images/illustrations/joined-user.png",
        },
        {
            button: {
                icon: "emoji_events",
                iconSrc: "/images/twitter/emojis/trophy.svg",
                text: "Leaderboard",
            },
            text: {
                title: "Show off your top members!",
                description: "Show off your top members with a leaderboard that always stays up to date.",
            },
            imageUri: "/images/illustrations/leaderboard.png",
        },
        {
            button: {
                icon: "terminal",
                text: "Logging",
                iconSrc: "/images/twitter/emojis/file-folder.svg",
            },
            text: {
                title: "Keep track all InviteCount actions!",
                description: "Never miss a beat with our logging system.",
            },
            imageUri: "/images/illustrations/logging.png",
        },
        {
            button: {
                icon: "lock",
                text: "Verification",
                iconSrc: "/images/twitter/emojis/lock.svg",
            },
            text: {
                title: "Keep your server safe!",
                description: "Keep your server safe with our verification system. No more fake invites!",
            },
            imageUri: "/images/illustrations/verification.png",
        },
        {
            button: {
                icon: "redeem",
                text: "Giveaways",
                iconSrc: "/images/twitter/emojis/tada.svg",
            },
            text: {
                title: "Set up giveaways for your members!",
                description: "Reward your members with giveaways and increase engagement.",
            },
            imageUri: "/images/illustrations/giveaway.png",
        },
    ];

    const nextSlide = useCallback(() => {
        if (currentSlide === slides.length - 1) {
            setSlide(0);
        } else {
            setSlide(currentSlide + 1);
        }
    }, [currentSlide]);

    const prevSlide = useCallback(() => {
        if (currentSlide === 0) {
            setSlide(slides.length - 1);
        } else {
            setSlide(currentSlide - 1);
        }
    }, [currentSlide]);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsShown(true);
                } else {
                    setIsShown(false);
                }
            },
            {
                root: null,
                rootMargin: "0px",
                threshold: 0.5,
            }
        );

        observer.observe(parent.current);

        return () => observer.disconnect();
    }, [parent]);

    useEffect(() => {
        if (isShown) {
            const interval = setInterval(() => {
                nextSlide();
            }, 5000);

            return () => clearInterval(interval);
        }
    }, [isShown, nextSlide]);

    return (
        <div className="container flex flex-col gap-24 z-20">
            <div className="w-full overflow-auto">
                <div className="flex flex-row flex-nowrap justify-start items-center gap-6">
                    {slides.map((slide, index) => (
                        <button
                            key={index}
                            onClick={() => setSlide(index)}
                            className={`
                            w-full py-7 px-10 flex flex-col gap-5 justify-center items-center rounded-2xl duration-200 hover:opacity-75 
                            ${currentSlide === index ? "bg-purple-100" : "bg-blue-400"}
                        `}
                        >
                            <img src={slide.button.iconSrc} alt="" width={48} height={48} />
                            <h3 className="font-bold text-xl">{slide.button.text}</h3>
                        </button>
                    ))}
                </div>
            </div>

            <div ref={parent} className="flex flex-row items-center gap-14">
                <span className="material-icons-outlined text-5xl flex-shrink-0 duration-200 cursor-pointer hover:opacity-75 hidden md:block" onClick={prevSlide}>
                    navigate_before
                </span>

                {slides.map((slide, index) => {
                    if (currentSlide !== index) return null;

                    return (
                        <div key={index} className={`flex-1 grid xl:grid-cols-2 gap-20`}>
                            <aside className="flex flex-col gap-4 my-auto text-center">
                                <h3 className="title xl:text-left">{slide.text.title}</h3>
                                <p className="text-xl max-w-3xl leading-relaxed xl:text-left">{slide.text.description}</p>
                            </aside>

                            <img src={slide.imageUri} alt="Slide" className="h-full object-contain" />
                        </div>
                    );
                })}

                <span className="material-icons-outlined text-5xl flex-shrink-0 duration-200 cursor-pointer hover:opacity-75 hidden md:block" onClick={nextSlide}>
                    navigate_next
                </span>
            </div>
        </div>
    );
};

export default function HomePage() {
    const excellences = [
        {
            icon: "dns",
            title: "Infrastructures",
            description: "We always strive to give you 99.99% bot uptime with no lag to ensure you have the best experience with InviteCount.",
        },
        {
            icon: "touch_app",
            title: "Easy To Use",
            description: "Bot configuration is explained in our documentation and we have also created a clear and easy to use web dashboard for you.",
        },
        {
            icon: "extension",
            title: "Multifunctional Bot",
            description: "InviteCount provides a range of features beyond just invitation tracking. You can schedule giveaways, reward members, track messages, and more!",
        },
    ];

    const faqs = [
        {
            question: "What is the prefix for InviteCount?",
            answer: "The default prefix for InviteCount is **/**. The bot works fully with slash commands.",
        },
        {
            question: "How can I get help about InviteCount?",
            answer: "If you need help with the bot join the [__InviteCount Support__](/discord) discord server.",
        },
        {
            question: "How can I vote for InviteCount?",
            answer: "You can vote for InviteCount on top.gg, click [__here__](/vote) to access our page. Thank you, voting really help us out!",
        },
        {
            question: "Where can i find more information about InviteCount?",
            answer: "You can find more information about InviteCount on our [__documentation__](/docs).",
        },
        {
            question: "What is a fake invite?",
            answer: "Fakes invites mean that the bot count members who joined under the minimum account age set using **/fakedelay** command.",
        },
    ];

    return (
        <main className="relative w-full min-h-screen font-poppins">
            <section className="relative w-full bg-gradient-to-br from-blue-400 via-blue-400 to-purple-100 pt-20 lg:pb-40 text-white overflow-clip">
                <div className="relative container flex flex-col gap-20 z-30">
                    <Navigation />

                    <div className="flex flex-col gap-6 md:gap-10 lg:max-w-2xl text-center lg:text-left">
                        <h1 className="font-bold text-5xl md:text-6xl !leading-snug lg:leading-normal">Manage All Your Connections In A Better Way!</h1>

                        <h2 className="text-xl md:text-2xl leading-relaxed md:leading-normal w-3/4 mx-auto lg:mx-0">
                            A powerful Discord bot which offers many features such as invite tracking, giveaways, messages tracking and more
                        </h2>

                        <Button onClick={() => Router.push("/dashboard")} light className="mx-auto lg:mx-0 animate-gradient bg-gradient-to-br from-purple-100 via-pruple-200 to-purple-200 shadow-md">
                            Add to Discord
                        </Button>
                    </div>
                </div>

                <div className="max-w-full lg:absolute bottom-0 right-0 overflow-clip h-[50%] w-full pt-10 lg:pt-0">
                    <img src="/images/illustrations/group-of-people.png" alt="Group of people" className="transform lg:translate-x-2/4 xl:translate-x-2/3 h-full" />
                </div>
            </section>

            <section className="relative w-full bg-blue-500 text-white py-20 z-20">
                <Slider />
                <img src="/images/confetti.svg" alt="" className="absolute bottom-0 left-0 w-full" />
            </section>

            <section className="relative w-full bg-blue-500 text-white py-20 z-20">
                <div className="container flex flex-col gap-24 z-20 justify-center">
                    <h1 className="title">Recommended By Those Who’s Chosen...</h1>

                    <div className="flex flex-col lg:flex-row items-center gap-16 mx-auto">
                        {excellences.map((excellence, index) => (
                            <div key={index} className="flex flex-col gap-8 items-center max-w-md">
                                <div className="w-24 h-24 bg-purple-200 rounded-full flex items-center justify-center">
                                    <span className="material-icons-outlined text-5xl">{excellence.icon}</span>
                                </div>

                                <div className="flex flex-col gap-2 text-center">
                                    <h3 className="font-bold text-2xl">{excellence.title}</h3>
                                    <p className="text-lg leading-relaxed">{excellence.description}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

                <svg className="absolute bottom-0 left-0 w-full fill-blue-400" viewBox="0 0 1920 305">
                    <path d="M-163 336.8L-83.8333 301.788C-4.66666 266.119 153.667 197.081 312 179C470.333 160.919 628.667 197.081 787 161.412C945.333 126.4 1103.67 21.1999 1262 3.6118C1420.33 -13.3188 1578.67 55.7187 1737 100.1C1895.33 144.481 2053.67 160.919 2132.83 170.288L2212 179V442H2132.83C2053.67 442 1895.33 442 1737 442C1578.67 442 1420.33 442 1262 442C1103.67 442 945.333 442 787 442C628.667 442 470.333 442 312 442C153.667 442 -4.66666 442 -83.8333 442H-163V336.8Z" />
                </svg>

                <svg className="absolute bottom-0 left-0 w-full fill-blue-400 opacity-50" viewBox="0 0 1920 372">
                    <path d="M2881 172.6L2784.2 194.201C2687.4 214.994 2493.8 259.406 2300.2 204.9C2106.6 150.394 1913 1.00626 1719.4 0.400635C1525.8 1.00626 1332.2 150.394 1138.6 183.299C945 214.994 751.4 130.206 557.8 97.3006C364.2 65.6063 170.6 85.7938 73.8 97.3006L-23 108V431H73.8C170.6 431 364.2 431 557.8 431C751.4 431 945 431 1138.6 431C1332.2 431 1525.8 431 1719.4 431C1913 431 2106.6 431 2300.2 431C2493.8 431 2687.4 431 2784.2 431H2881V172.6Z" />
                </svg>
            </section>

            <section className="relative w-full bg-gradient-to-b from-blue-400 to-blue-500 py-20 z-20 text-white">
                <div className="container flex flex-col lg:flex-row gap-10 xl:gap-24 z-20 items-center justify-center bg-gradient-to-br from-purple-100 to-purple-200 rounded-2xl px-8 md:px-16 py-16">
                    <img src="/images/illustrations/premium.png" alt="" className="xl:w-1/3" />

                    <div className="flex flex-col gap-7 text-center md:text-left">
                        <div className="flex flex-col gap-4">
                            <h2 className="text-xl md:text-2xl">Unleash Every Possibility With</h2>
                            <h1 className="title flex flex-col md:flex-row items-center gap-2 lg:text-left">
                                <span>
                                    <span className="text-transparent bg-gradient-to-r animate-gradient from-gold-300 to-gold-500 bg-clip-text">Premium</span> Version
                                </span>
                            </h1>
                        </div>

                        <p className="text-xl leading-normal">
                            You can subscribe to our Premium version of the bot which is offering embed messages, giveaway requirements, extra invite commands and more!
                        </p>

                        <a href="/premium" target="_blank" rel="noreferrer">
                            <Button className="!w-full lg:w-auto">See Packages</Button>
                        </a>
                    </div>
                </div>
            </section>

            <section className="relative w-full bg-blue-500 py-20 z-20 text-white">
                <div className="container flex flex-col gap-24 z-20 justify-center">
                    <h1 className="title">Frequently Asked Question</h1>

                    <div className="flex flex-col gap-8 max-w-4xl w-full mx-auto">
                        {faqs.map((faq, index) => (
                            <FAQ key={index} question={faq.question} answer={faq.answer} />
                        ))}
                    </div>
                </div>

                <svg className="absolute bottom-0 left-0 w-full fill-blue-400" viewBox="0 0 1920 321">
                    <path d="M0 0.114014L64 13.489C128 26.364 256 53.864 384 93.489C512 133.864 640 186.364 768 200.114C896 213.864 1024 186.364 1152 160.114C1280 133.864 1408 106.364 1536 106.739C1664 106.364 1792 133.864 1856 146.739L1920 160.114V320.114H1856C1792 320.114 1664 320.114 1536 320.114C1408 320.114 1280 320.114 1152 320.114C1024 320.114 896 320.114 768 320.114C640 320.114 512 320.114 384 320.114C256 320.114 128 320.114 64 320.114H0V0.114014Z" />
                </svg>

                <svg className="absolute bottom-0 left-0 w-full fill-blue-400 opacity-50" viewBox="0 0 1920 427">
                    <path d="M0 298.314H64C128 298.314 256 298.314 384 269.958C512 241.07 640 185.158 768 163.458C896 142.558 1024 155.87 1152 170.514C1280 185.158 1408 198.47 1536 170.514C1664 142.558 1792 70.6703 1856 35.6584L1920 0.114014V426.114H1856C1792 426.114 1664 426.114 1536 426.114C1408 426.114 1280 426.114 1152 426.114C1024 426.114 896 426.114 768 426.114C640 426.114 512 426.114 384 426.114C256 426.114 128 426.114 64 426.114H0V298.314Z" />
                </svg>
            </section>

            <Footer />
        </main>
    );
}
